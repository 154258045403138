import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import { defineStore } from "pinia";

initializeApp({
  apiKey: 'AIzaSyArt93m1MnUrJhhcrOk0-uahhs-Y-c5qxE',
  // authDomain: 'fortynamo.firebaseapp.com',
  projectId: 'fortynamo',
  storageBucket: 'fortynamo.appspot.com',
  messagingSenderId: '1006048077629',
  appId: '1:1006048077629:web:b5cda684a0286744a40754',
})
const auth = getAuth()
auth.languageCode = 'ko';
setPersistence(auth, browserSessionPersistence)

export const useAuthStore = defineStore('auth.store', {
  state: () => ({
    uid: '',
    phoneNo: ''
  }),
  getters: {
    isLoggedIn: (state) => state.uid !== ''
  },
  actions: {
    init () {
      auth.onAuthStateChanged((user) => {
        console.log(user)
        
        if(!user) {
          this.uid = ''
          this.phoneNo = ''
          return
        }

        this.uid = user.uid
        this.phoneNo = user.phoneNumber
      })
    }
  }
})