import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import GlobalAssets from '../../../assets'
import { Icons } from '../../../assets/icons'
import Assets from '../assets'
import useStore from './landing.store'

const Jumbotron = defineComponent({
  setup () {
    const store = useStore()
    const elRef = ref<HTMLDivElement>()
    onMounted(() => {
      store.observer.observe(elRef.value)
    })

    return () => (
      <div
        class={`
          relative
          pt-24 pb-0 bg-[#F8F8F8]
          md:bg-transparent md:pb-24
        `}
        id="메인"
        ref={elRef}
      >
        <div class="flex max-w-5xl mx-auto px-5 items-center flex-col md:flex-row">
          <div class="flex basis-full md:basis-1/2 flex-col items-center md:items-start text-center md:text-left">
            <div>
              <img src={GlobalAssets.logo} class="w-1/2 inline-block"/>
            </div>
            <div class="mt-7 text-4xl text-[#245058] font-bold">
              {store.isPublic ? '직장인 정신건강 설문조사': 'MOTIVE 심리진단'} 
            </div>
            <div class="text-lg font-light" v-show={!store.isPublic}>
              Mental Oriented Team and IndiVidual Examination
            </div>
            <div class="mt-8 text-lg md:text-xl text-[#282828]">
              {
                store.isPublic 
                ? '조직의 객관적, 주관적 환경이 개인의 정신건강 및 스트레스에 미치는 영향을 분석해드립니다.' 
                : '조직과 개인의 건강한 성장을 위한 복합솔루션'
              }
            </div>
            <div class="mt-6 w-full">
              <a
                class="bg-[#245058] text-white font-bold flex px-2 py-3 items-center w-full rounded-md cursor-pointer hover:bg-[#2F646D] md:w-[202px]"
                onClick={store.goToNext}
              >
                <div>
                  <img src={Icons.board} style={{ width: '23px' }} />
                </div>
                <div class="grow text-center">참여하기</div>
                <div class="invisible">
                  <img src={Icons.board} style={{ width: '23px' }} />
                </div>
              </a>
            </div>
          </div>
          <div class="basis-full mt-4 md:mt-0 md:basis-1/2 -mx-5 md:mx-0">
            <img src={Assets.jumbo_background} />
          </div>
        </div>
        <div class="absolute hidden md:flex bottom-4 left-1/2 -translate-x-1/2 text-[#245058] font-bold text-sm">
          <img src={Icons.finger} style={{ width: '20px' }} />
          <span class="ml-1">자세히보기</span>
        </div>
      </div>
    )
  },
})

const Navigation = defineComponent({
  setup () {
    const store = useStore()

    return () => (
      <div class="border-y flex justify-center text-sm sticky top-0 bg-white z-10">
        <a
          class={` border-b-[3px] pt-4 pb-3 mx-4 md:mx-10 ${
            store.currentPosition === '검사내용'
              ? 'border-b-[#245058] font-bold text-[#245058]'
              : 'border-b-transparent text-[#282828]'
          }`}
          href="#검사내용"
        >
          검사내용
        </a>
        <a
          class={` border-b-[3px] pt-4 pb-3 mx-4 md:mx-10 ${
            store.currentPosition === '참여방법'
              ? 'border-b-[#245058] font-bold text-[#245058]'
              : 'border-b-transparent text-[#282828]'
          }`}
          href="#참여방법"
        >
          참여방법
        </a>
      </div>
    )
  },
})

const Intro = defineComponent({
  setup () {
    const store = useStore()
    const elRef = ref<HTMLDivElement>()
    onMounted(() => {
      store.observer.observe(elRef.value)
    })
    return () => (
      <div class="pt-28 pb-28 px-5 overflow-hidden" id="검사내용" ref={elRef}>
        <div class="relative max-w-xs mx-auto md:max-w-5xl">
          <img src={store.isPublic ? Assets.mobile_intro_B : Assets.mobile_intro} class="w-full md:hidden" />
          <img src={store.isPublic ? Assets.pc_intro_B :Assets.pc_intro} class="w-full hidden md:block" />
        </div>
      </div>
    )
  },
})

const Method = defineComponent({
  setup () {
    const store = useStore()
    const elRef = ref<HTMLDivElement>()
    onMounted(() => {
      store.observer.observe(elRef.value)
    })
    return () => (
      <div class="pt-24 pb-20 px-5" id="참여방법" ref={elRef}>
        <div class="max-w-xs mx-auto md:max-w-5xl">
          <img src={store.isPublic ? Assets.mobile_method_B :Assets.mobile_method} class="w-full md:hidden" />
          <img src={store.isPublic ? Assets.pc_method_B :Assets.pc_method} class="w-full hidden md:block" />
        </div>
      </div>
    )
  },
})

const Reward = defineComponent({
  setup () {
    const store = useStore()
    return () => (
      <div class="pt-24 px-5 relative">
        <div class="absolute inset-0 bg-cover bg-center" style={{ background: `url(${Assets.reward_background})` }}></div>
        <div class="relative max-w-xs mx-auto md:max-w-5xl z-[1]">
          <img src={store.isPublic ? Assets.mobile_reward_B :Assets.mobile_reward} class="w-full md:hidden" />
          <img src={store.isPublic ? Assets.pc_reward_B :Assets.pc_reward} class="w-full hidden md:block" />
        </div>
      </div>
    )
  },
})

export default defineComponent({
  setup () {
    const store = useStore()
    store.init()

    onBeforeUnmount(() => {
      store.observer.disconnect()
    })
    
    return () => (
      <div>
        <Jumbotron />
        <Navigation />
        <Intro />
        <Method />
        <Reward />
        <div
          v-show={store.currentPosition !== '메인'}
          class="fixed bottom-0 w-full md:hidden px-4 py-3 bg-white border-t z-10"
        >
          <a
            class="bg-[#245058] text-white font-bold flex px-2 py-3 items-center w-full rounded-md cursor-pointer hover:bg-[#2F646D] md:w-[202px]"
            onClick={store.goToNext}
          >
            <div>
              <img src={Icons.board} style={{ width: '23px' }} />
            </div>
            <div class="grow text-center">참여하기</div>
            <div class="invisible">
              <img src={Icons.board} style={{ width: '23px' }} />
            </div>
          </a>
        </div>
      </div>
    )
  },
})
