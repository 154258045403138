import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { defineComponent, reactive } from 'vue';


export default defineComponent({
  name: 'login',
  setup() {

    const state = reactive({
      state: 'ready' as 'ready' | 'code-sent',
      loading: false,
      tel: '',
      code: ''
    })

    let onCodeEntered: () => Promise<void> | null = null
    let recaptchaVerifier: RecaptchaVerifier

    const onClick = async () => {
      const auth = getAuth();
      if(!recaptchaVerifier) {
        recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
          'size': 'invisible',
        }, auth);
      }

      const tel = state.tel.trim()
      if(!tel) {
        alert('전화번호를 입력해주세요.')
        return
      }
      state.loading = true

      const normTel = '+82'+tel.slice(1).replace('-', ' ')

      const confirmationResult = await signInWithPhoneNumber(auth, normTel, recaptchaVerifier)
        .catch((err) => {
          alert('에러가 발생했습니다. ' + err.message)
          state.loading = false
          throw err
        })
      state.state = 'code-sent'
      state.loading = false
      
      onCodeEntered = async () => {
        if(state.code.length !== 6) return

        state.loading = true

        await recaptchaVerifier.verify()
        await confirmationResult.confirm(state.code)
          .catch(err => {
            alert('에러가 발생했습니다. ' + err.message)
          })
        state.loading = false
      }
    }

    return () => (
      <div class="flex items-center flex-col">
        <div class="flex items-center flex-col" v-show={state.state === 'ready'}>
          <div>
            <input 
              class="border rounded-lg px-4 py-2 min-w-[400px]"
              type="text" 
              placeholder="핸드폰번호를 입력해주세요 (ex. 010-1234-5678)"
              onInput={(e) => {
                const target = e.target as HTMLInputElement
                let tel = target.value.trim()
                tel = tel.replace(/[^0-9]/g, '')
                if(tel.length > 11) tel = tel.slice(0, 11)
                
                if(tel.length > 7){
                  tel = tel.replace(/(\d{3})(\d{1,4})(\d{4})/, '$1-$2-$3')
                } else if(tel.length > 3){
                  tel = tel.replace(/(\d{3})(\d+)/, '$1-$2')
                }
                state.tel = tel
                target.value = tel
              }}
              onKeydown={(e) => {
                if(e.key === 'Enter') onClick()
              }}
              value={state.tel} />
          </div>
          <div class="mt-2">
            <button 
              id="sign-in-button" 
              class="border rounded-lg bg-blue-600 px-4 py-2 text-white" 
              onClick={onClick}
              disabled={state.loading}>
              {state.loading ? '로딩중...' : '인증번호 받기'}
            </button>
          </div>
        </div>
        <div class="flex flex-col items-center" v-show={state.state === 'code-sent'}>
          <div class="text-center">
            번호 {state.tel}에 <br/>
            문자메시지로 인증번호가 전송되었습니다. <br/>
            확인후 아래에 입력해주세요.
          </div>
          <div class="mt-2">
            <input 
              class="border rounded-lg px-4 py-2 w-[400px] max-w-full text-center"
              type="text" 
              placeholder="핸드폰으로 전송된 6자리 인증번호를 입력해주세요"
              onInput={(e) => {
                const target = e.target as HTMLInputElement
                state.code = target.value.trim()
              }} 
              onKeydown={(e) => {
                if(e.key === 'Enter') onCodeEntered()
              }}
              value={state.code} />
          </div>
          <div class="mt-2">
            <button
              class="border rounded-lg bg-green-600 px-4 py-2 text-white" 
              onClick={onCodeEntered}
              disabled={state.loading}>
                {state.loading ? '로딩중...' : '인증하기'}
              </button>
          </div>
        </div>
      </div>
    )
  }
})