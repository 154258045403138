import type { GetReportDto } from '../../../../server/src/types'

const URL = 'https://asia-northeast3-fortynamo.cloudfunctions.net/aooaLandingServer'
// const URL = 'http://0.0.0.0:5001/fortynamo/asia-northeast3/aooaLandingServer'

export const useReportAPI = () => {
  const getReport = async (dto: GetReportDto) => {
    const res = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return res.json()
  }

  return {
    getReport   
  }
}