import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Landing from './modules/landing/views/landing'
import Dashboard from './modules/report/views/dashboard'
import IndividualReport from './modules/report/views/individual'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/report/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/report/individual',
    name: 'individual report',
    component: IndividualReport,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
