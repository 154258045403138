import { getAuth } from 'firebase/auth'
import { defineComponent, ref, Teleport } from 'vue'
import { useRouter } from 'vue-router'
import { Icons } from '../../../assets/icons'
import { Dialog } from '../../../components/dialog'
import { useAuthStore } from '../../../stores/auth.store'
import FLogin from '../components/login'
import useStore from '../store'

const LegacyLogin = defineComponent({
  setup() {
    const store = useStore()
    
    return () => (
      <div>
        <div class="mt-8">
          전화번호 인증 이전 검사한 유저들은 여기서 로그인할 수 있습니다.
        </div>
        <div class="flex items-start mt-2 flex-col sm:flex-row sm:items-center">
          <label for="tel" class="basis-8 sm:basis-28 font-bold">전화번호</label>
          <input
            id="tel"
            value={store.tel}
            onInput={(e) => store.setTel((e.target as HTMLInputElement).value)}
            type="tel"
            placeholder='전화번호'
            class="rounded border p-4 grow min-w-0 w-full"
          />
        </div>
        <div class="flex items-start mt-8 flex-col sm:flex-row sm:items-center">
          <label for="password" class="basis-8 sm:basis-28 font-bold">비밀번호</label>
          <input
            id="password"
            v-model={store.password}
            type="password"
            placeholder='비밀번호'
            class="rounded border p-4 grow min-w-0 w-full"
          />
        </div>
        <div class="flex items-start mt-8 flex-col sm:flex-row sm:items-center">
          <label for="password2" class="basis-8 sm:basis-28 font-bold">비밀번호 검증</label>
          <input
            id="password2"
            v-model={store.password2}
            type="password"
            placeholder='비밀번호 검증'
            class="rounded border p-4 grow min-w-0 w-full"
          />
        </div>
        <div v-show={store.password !== store.password2} class="text-red-600">
          비밀번호가 일치하지 않습니다.
        </div>
      </div>
    )
  }
})
const Login = defineComponent({
  setup() {
    const authStore = useAuthStore()

    return () => (
      <div>
        <div v-show={authStore.isLoggedIn} class="flex flex-col items-center">
          <div>로그인됨</div>
          <div>핸드폰번호: {authStore.phoneNo}</div>
          <button class="bg-red-600 px-4 py-2 text-white rounded-lg cursor-pointer" onClick={() => {
            const auth = getAuth()
            auth.signOut()
          }}>로그아웃</button>
        </div>
        <div v-show={!authStore.isLoggedIn}>
          <FLogin/>
        </div>
      </div>
    )
  }
})



const ReportInfo = defineComponent({
  setup() {
    const store = useStore()

    return () => (
      <div>
        <div class="flex items-start mt-8 flex-col sm:flex-row sm:items-center">
          <label for="username" class="basis-8 sm:basis-28 font-bold">이름</label>
          <input
            id="username"
            v-model={store.username}
            type="text"
            placeholder='레포트에 적힐 이름 혹은 닉네임'
            class="rounded border p-4 grow min-w-0 w-full"
          />
        </div>
      </div>
    )
  }
})


export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()
    const authStore = useAuthStore()

    store.init()

    const showPopup = ref(false)

    return () => (
      [<div class="px-4 max-w-screen-lg mx-auto">
        <div class="text-center pt-16 text-xl font-bold">
          응답 후 개인 보고서 열람을 위해 전화번호 인증을 진행해주세요
        </div>
        <Login class="mt-16"/>
        <div class="py-4"/>
        <div class="flex mt-8">
          <button
            class="text-center p-4 bg-[#245058] text-white rounded-lg w-full disabled:bg-gray-400"
            onClick={() => {
              if(Date.now() > 1667898000000 && store.companyCode === 'AOOA_137plus3_public_surey') {
                showPopup.value = true
              } else {
                window.open(
                  `https://fortynamo.appspot.com.storage.googleapis.com/cdn/ccms/index.html?schedulemode=${store.scheduleMode}&globalUid=${store.uid}#/TrialViewer/${store.companyCode}`,
                  '_blank'
                )
              }
            }}
            disabled={!store.isLoggedIn}
          >
            <div class="flex justify-center items-center">
              <div class="mr-2">
                <img src={Icons.board} style={{ width: '23px' }} />
              </div>
              검사 시작하기
            </div>
          </button>
        </div>
        <ReportInfo v-show={store.isLoggedIn && store.companyCode !== 'MOTIVE_EZNW'} />
        <div class="flex mt-8" v-show={store.companyCode !== 'MOTIVE_EZNW'}>
          <button class="text-center p-4 bg-[#245058] text-white rounded-lg w-full disabled:bg-gray-400 cursor-pointer"
            onClick={() => {
              if(!store.reportEnabled.state) {
                alert(store.reportEnabled.message)
                return
              }
              router.push({
                name: 'individual report',
              })
            }}
          disabled={!store.isLoggedIn || !store.username.trim()}>
            <div class="flex justify-center items-center">
              <div class="mr-2">
                <img src={Icons.board} style={{ width: '23px' }} />
              </div>
              검사 결과보기
            </div>
          </button>
        </div>
        <div class="mt-8 text-center" v-show={store.isLoggedIn && store.companyCode === 'MOTIVE_EZNW'}>
          MOTIVE 개인결과보고서는 개인별 상담 당일 오전에 전송해드립니다.
        </div>
      </div>,
      <Teleport to="body">
        <Dialog visible={showPopup.value}
          onClose={() => { showPopup.value = false }}
          closeOnBackdrop={true}>
          <div class="py-12 px-4 text-center grow">
            많은 분들의 성원에 힘입어 직장인 심리자원 검사가 마감되었습니다. 감사합니다.
          </div>
        </Dialog>
      </Teleport>]
    )
  },
})
