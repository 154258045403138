import { Report } from 'org_report'
import { defineComponent } from 'vue'
import { Spinner } from '../../../components/spinner'
import useStore from '../store'

export default defineComponent({
  setup() {
    const store = useStore()
    store.fetchReport()

    return () => {
      if(store.fetchStatus === 'loading') return (
        <div class="flex justify-center py-8">
          <Spinner class="text-black mr-2"/> 로딩중...
        </div>
      )

      if(store.fetchStatus === 'failed') return (
        <div class="text-center py-8">
          조회 실패. 계정 정보를 확인해주세요. <br/>
          보고서 생성까지 최대 3주가 소요될 수 있습니다.
        </div>
      )
      
      return (
        <div class="bg-gray-200" id="to_pdf">
          <Report
            reportData={store.report}/>
        </div>
      )
    } 
  }
})

