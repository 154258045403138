import 'core-js/stable'
import { createApp } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import './styles/globals.css'

import { createPinia } from 'pinia'
import router from './router'
import { useAuthStore } from './stores/auth.store'

const pinia = createPinia()

const app = createApp({
  setup () {
    const authStore = useAuthStore()
    authStore.init()
    
    return () => (
      <main>
        <RouterView></RouterView>
      </main>
    )
  },
})

pinia.use(() => {
  const route = useRoute()
  return {
    router,
    route
  }
})
app.use(pinia).use(router).mount('#app')
