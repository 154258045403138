export default {
  jumbo_background: require('./jumbo_background.png'),
  mobile_intro: require('./mobile_intro.png'),
  mobile_method: require('./mobile_method.png'),
  mobile_reward: require('./mobile_reward.png'),
  pc_intro: require('./pc_intro.png'),
  pc_method: require('./pc_method.png'),
  pc_reward: require('./pc_reward.png'),
  mobile_intro_B: require('./mobile_intro_B.png'),
  mobile_method_B: require('./mobile_method_B.png'),
  mobile_reward_B: require('./mobile_reward_B.png'),
  pc_intro_B: require('./pc_intro_B.png'),
  pc_method_B: require('./pc_method_B.png'),
  pc_reward_B: require('./pc_reward_B.png'),
  reward_background: require('./reward_background.png'),
}
