import { CSSProperties, defineComponent, PropType } from 'vue'

export const Dialog = defineComponent({
  props: {
    visible: { type: Boolean, required: true },
    showCloseButton: { type: Boolean, default: true },
    bodyStyle: { type: Object as PropType<CSSProperties>, default: () => ({}) },
    closeOnBackdrop: { type: Boolean, default: false },
  },
  emits: {
    close: () => true,
  },
  setup (props, { emit, slots }) {
    const close = () => emit('close')
    return () => {
      if (!props.visible) return null

      return (
        <div
          class={`
            fixed left-0 right-0 top-0 bottom-0 z-50
            flex items-center justify-center bg-[rgba(0,0,0,0.8)]
          `}
          onClick={() => {
            props.closeOnBackdrop && close()
          }}
        >
          <div
            class={`
              relative w-[82.5vw] max-w-xl
              rounded bg-white animate-bounce-in
              max-h-screen scrollbar
            `}
            onClick={e => e.stopPropagation()}
          >
            <div class="absolute right-0 top-0 p-4">
              {/* TODO 나중에 새로운 Icon 컴포넌트로 바꾸기! */}
              {props.showCloseButton
                ? (
                <div
                  class="w-5 h-5 cursor-pointer text-2xl text-gray-600"
                  onClick={close}
                >
                  &times;
                </div>
                  )
                : null}
            </div>
            <div style={props.bodyStyle} class="flex">
              {slots.default?.()}
            </div>
          </div>
        </div>
      )
    }
  },
})
