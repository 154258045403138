import { defineStore } from "pinia";

export default defineStore('landing.landing', {
  state: () => ({
    currentPosition: '메인' as '메인'|'검사내용'|'참여방법',
    observer: null as IntersectionObserver
  }),
  getters: {
    programId(): string {
      const programId = this.route.query.c as string || 'AOOA_137plus3_public_surey'
      return programId
    },
    isPublic(): boolean {
      return this.programId === 'MOTIVE_PUBLICSURVEY_2302'
    },
    randomUid(): string {
      const charpool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
      const buffer = new Uint8Array(24)
      crypto.getRandomValues(buffer)
      let str = ''
      for (const c of buffer) {
        str += charpool[c % charpool.length]
      }
      return str
    }
  },
  actions: {
    init(){
      this.observer = new IntersectionObserver(
        (items) => {
          items = items.filter(el => el.isIntersecting)
          if (items.length === 0) return
      
          items.sort((a, b) => a.intersectionRatio - b.intersectionRatio)
          this.currentPosition = items[0].target.id as any
        },
        {
          rootMargin: '20px 0px 0px 0px',
          threshold: [0.5],
        },
      )
    },
    goToNext() {
      if(this.isPublic) {
        window.open(
          `https://fortynamo.appspot.com.storage.googleapis.com/cdn/ccms/index.html?schedulemode=x04&globalUid=${this.randomUid}#/TrialViewer/${this.programId}`,
          '_blank'
        )
      } else {
        this.router.push({
          name: 'dashboard',
          query: {
            c: this.programId
          }
        })
      }
    }
  }
})