import { SHA256 } from 'crypto-js';
import type { IndividualReportData } from 'org_report_lib/query-data/types';
import { defineStore } from "pinia";
import { nextTick } from 'vue';
import { useReportAPI } from '../../../api/report';
import { useAuthStore } from '../../../stores/auth.store';

export default defineStore('modules.report', {
  state: () => ({
    tel: '',
    password: '',
    password2: '',
    username: '',
    companyCode: 'AOOA_137plus3_public_surey',
    report: null as IndividualReportData | null,
    fetchStatus: 'loading' as 'loading' | 'loaded' | 'failed'
  }),
  getters: {
    uid: (state) => {
      const authStore = useAuthStore()
      if(authStore.isLoggedIn) return authStore.uid

      const arr =  SHA256(`tel:${state.tel}/password:${state.password}`)
      return arr.toString()
      // return 'brw==ARqL9qouOMvfiq350zv5'
    },
    companyName: (state) => {
      if(state.companyCode === 'MOTIVE_OPINNO')
        return '(주)오피노마케팅'
      return '-'
    },
    isLoggedIn: (state) => {
      const authStore = useAuthStore()
      if(authStore.isLoggedIn) return true

      return state.tel !== '' && state.password !== '' && state.password === state.password2
    },
    assessmentEnabled: (state) => {
      if(state.companyCode === 'AOOA_137plus3_public_surey') {
        return {
          state: Date.now() <= 1667898000000, // 2022년 11월 8일 오후 6시
          message: '2022년 11월 8일 오후 6시까지만 설문에 참여할 수 있습니다.'
        }
      }
      if(state.companyCode === 'MOTIVE_REDWIT_11141116') {
        return {
          state: Date.now() <= 1670252400000, // 2022년 12월 6일 
          message: '2022년 12월 6일 00시까지만 설문에 참여할 수 있습니다.'
        }
      }
      if(state.companyCode === 'MOTIVE_UPSTAGE_11111125') {
        return {
          state: Date.now() <= 1670252400000, // 2022년 12월 6일 
          message: '2022년 12월 6일 00시까지만 설문에 참여할 수 있습니다.'
        }
      }
      if(state.companyCode === 'MOTIVE_OPINNO') {
        return {
          // 2023년 1월 25일 ~ 2월 3일 24시
          state: 1675436400000 >= Date.now() && Date.now() > 1674572400000,
          message: '2023년 1월 25일 00시 ~ 1월 31일 24시 사이에만 설문에 참여할 수 있습니다.'
        }
      }
      if(state.companyCode === 'MOTIVE_trialVER')
        return { 
          state: true, 
          message: ''
        }
      if(state.companyCode === 'MOTIVE_EZNW')
        return { 
          state: true, 
          message: ''
        }
      if(state.companyCode === 'MOTIVE_MCKINSEY_11251205') 
        return { 
          state: Date.now() <= 1670943600000, // 2022년 12월 14일 00시
          message: '2022년 12월 14일 00시까지만 설문에 참여할 수 있습니다.'
        }
      if(state.companyCode === 'MOTIVE_HMM_02140215') 
        return { 
          state: Date.now() <= 1676559600000, // 2023년 2월 16일 24시
          message: '2023년 2월 16일 24시까지만 설문에 참여할 수 있습니다.'
        }
      if(state.companyCode === 'MOTIVE_KI_03100314') 
        return { 
          state: 1678374000000 <=  Date.now() && Date.now() <= 1678806000000, // 3/10 ~ 3/14
          message: '2023년 3월 10일 ~ 3월 14일 24시까지만 설문에 참여할 수 있습니다.'
        }
      if(state.companyCode === 'MOTIVE_orangeplanet') 
        return { 
          state: 1678633200000 <=  Date.now(), // 3/13 ~
          message: '2023년 3월 13일 부터 설문에 참여할 수 있습니다.'
        }
      if(state.companyCode === 'MOTIVE_fsec') 
        return { 
          state: 1683471600000 <=  Date.now() && Date.now() <= 1685113200000, // 5/8 ~ 5/26 24시
          message: '2023년 5월 8일 ~ 5월 26일 24시 사이에만 설문에 참여할 수 있습니다.'
        }
        if(state.companyCode === 'MOTIVE_Kyobo')
          return { 
            state: 1688482800000 <=  Date.now() && Date.now() <= 1689346800000, // 7/5 ~ 7/14 24시
            message: '2023년 7월 5일 ~ 7월 14일 사이에 조회 가능합니다.'
          }
        
      return {
        state: false,
        message: '존재하지 않는 설문'
      }
    },
    reportEnabled: (state): { state: boolean, message: string } => {
      if(state.companyCode === 'AOOA_137plus3_public_surey')
        return { state: true, message: '' }
      if(state.companyCode === 'MOTIVE_REDWIT_11141116') 
        return { 
          // 2022년 11월 21일 ~ 12월 16일 24시
          state: 1671202800000 >= Date.now() && Date.now() > 1668956400000, 
          message: '2022년 11월 21일 ~ 12월 16일 사이에 조회 가능합니다.' 
        }
      if(state.companyCode === 'MOTIVE_UPSTAGE_11111125') 
        return { 
           // 2022년 11월 23일 ~ 12월 29일 24시
          state: 1675350000000 >= Date.now(),
          message: '2023년 2월 2일 24시까지 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_trialVER')
        return { 
          state: true, 
          message: ''
        }
      if(state.companyCode === 'MOTIVE_MCKINSEY_11251205') 
        return { 
          // 2022년 12월 6일 오전 11시 ~ 12월 20일 24시
          state: 1671462000000 >= Date.now() && Date.now() > 1670292000000, 
          message: '2022년 12월 6일 ~ 12월 20일 사이에 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_OPINNO') 
        return { 
          // 2023년 2월 1일 오전 11시 ~ 2월 17일 24시
          state: 1676646000000 >= Date.now() && Date.now() > 1675216800000, 
          message: '2023년 2월 1일 오전 11시 ~ 2월 17일 사이에 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_EZNW') 
        return { 
          state: false, message: 'MOTIVE 개인결과보고서는 개인별 상담 당일 오전에 전송해드립니다.'
        }
      if(state.companyCode === 'MOTIVE_HMM_02140215')
        return { 
          state: 1677250800000 >= Date.now()  && Date.now() >= 1676559600000, // 2023년 2월 17일 ~ 24일 24시
          message: '2023년 2월 17일 ~ 2월 24일 사이에 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_KI_03100314')
        return { 
          state: 1678719600000 <=  Date.now() && Date.now() <= 1680274800000, // 3/14 ~ 3/31
          message: '2023년 3월 14일 ~ 3월 31일 사이에 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_orangeplanet')
        return { 
          state: 1678719600000 <=  Date.now(), // 3/14 ~
          message: '2023년 3월 14일 이후부터 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_fsec')
        return { 
          state: 1685113200000 <=  Date.now() && Date.now() <= 1688137200000, // 5/27 ~ 6/30 24시
          message: '2023년 5월 27일 ~ 6월 30일 사이에 조회 가능합니다.'
        }
      if(state.companyCode === 'MOTIVE_Kyobo')
        return { 
          state: 1689519600000 <=  Date.now() && Date.now() <= 1693494000000, // 7/17 ~ 8/31 24시
          message: '2023년 7월 17일 ~ 8월 31일 사이에 조회 가능합니다.'
        }
      
      return { state: false, message: '존재하지 않는 프로그램 id' }
    },
    scheduleMode: state => {
      if(['MOTIVE_EZNW', 'MOTIVE_OPINNO', 'MOTIVE_HMM_02140215', 'MOTIVE_KI_03100314', 'MOTIVE_orangeplanet', 'MOTIVE_fsec'].includes(state.companyCode)) return 'x07'
      return 'x04'
    }
  },
  actions: {
    init () {
      this.companyCode = this.route.query.c as string || 'AOOA_137plus3_public_surey'
    },
    setTel(val: string){
      this.tel = val.replace(/[^0-9]/g, '')
      if(this.tel.length > 11) this.tel = this.tel.slice(0, 11)
      
      if(this.tel.length > 7){
        this.tel = this.tel.replace(/(\d{3})(\d{1,4})(\d{4})/, '$1-$2-$3')
      } else if(this.tel.length > 3){
        this.tel = this.tel.replace(/(\d{3})(\d+)/, '$1-$2')
      }
      
      if(this.tel === ''){
        this.tel = '-'
        nextTick(() => this.tel = '')
      }
    },
    async fetchReport(){
      const { username, companyCode, uid, companyName } = this
      
      if(!uid || !username || !companyCode) {
        this.fetchStatus = 'failed'
        return alert('정보를 모두 채워주세요')
      }
  
      const { getReport } = useReportAPI()
      this.fetchStatus = 'loading'
      const res = await getReport({
        uid,
        name: username,
        companyCode,
        companyName
      })
      if(res.success === false){
        this.fetchStatus = 'failed'
      } else {
        this.report = res.ret
        this.fetchStatus = 'loaded'
      }
    },
  }
})